<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <rubisco-riepilogo-carrello :data="data_crm.carrello" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <rubisco-statistiche :data="data_crm.statisticsItems" />
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <rubisco-contatti />
      </b-col>

      <b-col lg="8">
        <rubisco-banner :data="data_crm.banner" />
      </b-col>
    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import RubiscoRiepilogoCarrello from './RubiscoRiepilogoCarrello.vue'
import RubiscoStatistiche from './RubiscoStatistiche.vue'
import RubiscoContatti from './RubiscoContatti.vue'
import RubiscoBanner from './RubiscoBanner.vue'

export default {
  components: {
    BRow,
    BCol,

    RubiscoRiepilogoCarrello,
    RubiscoStatistiche,
    RubiscoContatti,
    RubiscoBanner,
  },
  data() {
    return {
      userData: {},
      data_crm: {
        carrello: { },
        statisticsItems: [],
      },
    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))

    this.$http.get('v2/azienda/scrivaniaInfo/'+this.userData.id_azienda)
      .then(response => {
        this.data_crm = response.data

        this.data_crm.carrello.name = this.userData.fullName.split(' ')[0] || this.userData.username

        console.log(response.data_crm);
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
