<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/sales.svg')"
        alt="info e contatti"
        height="170"
      />
    </div>
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="my-auto">
          <b-card-title class="mb-25">
            Sicurezza 88
          </b-card-title>
          <b-card-text class="mb-0">
           Assistenza Clienti
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->

      
    <b-media no-body>
        <b-media-aside class="mr-1">
        <b-avatar
            rounded
            variant="light-primary"
            size="34"
        >
            <feather-icon
            icon="PhoneIcon"
            size="18"
            />
        </b-avatar>
        </b-media-aside>
        <b-media-body>
        <h6 class="mb-0">
            0571 112233</h6>
        </b-media-body>
    </b-media>
    <b-media no-body>
        <b-media-aside class="mr-1">
        <b-avatar
            rounded
            variant="light-primary"
            size="34"
        >
            <feather-icon
            icon="MailIcon"
            size="18"
            />
        </b-avatar>
        </b-media-aside>
        <b-media-body>
        <h6 class="mb-0">
            info@sicurezza88.com</h6>
        </b-media-body>
    </b-media>
      <b-media no-body>
        <b-media-aside class="mr-1">
        <b-avatar
            rounded
            variant="light-primary"
            size="34"
        >
            <feather-icon
            icon="MapIcon"
            size="18"
            />
        </b-avatar>
        </b-media-aside>
        <b-media-body>
        <h6 class="mb-0">
            Via delle Rose, 38<br />San Casciano in Val di Pesa (FI)
        </h6>
        </b-media-body>
    </b-media>


    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
