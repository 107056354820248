<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5>Salve {{ data.name }}!</h5>
    <div v-if="data.articoli_carrello > 0">
      <b-card-text class="font-small-3">
        hai {{ data.articoli_carrello }} 
        <span v-if="data.articoli_carrello == '1'">articolo</span>
        <span v-if="data.articoli_carrello != '1'">articoli</span> nel carrello
      </b-card-text>
      <h3 class="mb-75 mt-0">
        <b-link> {{ data.totale_carrello }} €</b-link>
      </h3>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        to="../catalogo/carrello"
      >
        Conferma Ordine
      </b-button>
    </div>
    <div v-if="data.articoli_carrello == 0">
      <b-card-text class="font-small-3">Conosci il nostro catalogo?</b-card-text>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'catalogo-articoli' }"
        class="mt-2"
      >
        Catalogo Articoli
      </b-button>
    </div>
    
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
