<template>
  <b-card
    no-body
    v-if="data"
  >
    <!--
    <b-link
      :to="{ name: data.link_banner }"
    >
      <b-img
        :src="data.url_banner"
        alt="banner"
        class="img-fluid"
      />
    </b-link>
    -->
    
  </b-card>
</template>

<script>
import { BCard, BImg, BLink, } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BImg,
    BLink,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
